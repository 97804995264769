import gsap from "gsap";
import SplitType from 'split-type';
let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {
   const btnBubble = document.querySelectorAll(".btn-bubble");
   //const cursorBox = document.querySelector(".cursor-box");
   const cursor = document.querySelector(".cursor-def");

   btnBubble.forEach((btn) => {

      let btnBubbleCircl = btn.querySelector(".btn-bubble__circle");
      let btnBubbleText = btn.querySelector(".btn-bubble__text");

      new SplitType(btnBubbleText, {
         types: "chars",
         tagName: "span"
      });
      const btnBubbleTextChars = gsap.utils.toArray(".char", btnBubbleText);



      let btnBubbleTextCharsTL = gsap.timeline({
         paused: true
      });
      btnBubbleTextCharsTL.to(btnBubbleTextChars, {
         duration: 0.2,
         yPercent: -100,
         ease: "power1.out",
         stagger: 0.006
      });
      btnBubbleTextCharsTL.set(btnBubbleTextChars, {
         duration: 0,
         yPercent: 100
      });
      btnBubbleTextCharsTL.to(btnBubbleTextChars, {
         duration: 0.2,
         yPercent: 0,
         ease: "power1.out",
         stagger: 0.006
      })
      btnBubbleTextCharsTL.addPause("exit");





      let btnBubbleCirclTL = gsap.timeline({
         paused: true,
         defaults: {
            duration: 0.8,
            ease: "power1.out"
         }
      });
      btnBubbleCirclTL.to(btnBubbleCircl, {
         scale: 2,
         yPercent: 0,
      })
      btnBubbleCirclTL.addPause("exit");




      let btnBubbleCirclLeaveTL = gsap.timeline({
         paused: true,
         defaults: {
            ease: "power1.out"
         }
      });
      btnBubbleCirclLeaveTL.to(btnBubbleCircl, {
         duration: 0.7,
         scale: 1,
         yPercent: -150,
      });
      btnBubbleCirclLeaveTL.set(btnBubbleCircl, {
         duration: 0,
         yPercent: 0,
         scale: 1,
      })
      btnBubbleCirclLeaveTL.addPause("exit");





      let exitTime = 0;
      exitTime = btnBubbleTextCharsTL.duration();
      btnBubbleTextCharsTL.to(btnBubbleTextChars, {
         yPercent: 0
      })

      let exitTime2 = 0;
      exitTime2 = btnBubbleCirclTL.duration();
      btnBubbleCirclTL.to(btnBubbleCircl, {
         yPercent: 0
      })
      let exitTime3 = 0;
      exitTime3 = btnBubbleCirclLeaveTL.duration();
      btnBubbleCirclLeaveTL.to(btnBubbleCircl, {
         yPercent: 0
      })



      btn.addEventListener("mouseenter", () => {
         cursor.style.transform = "scale(0)";

         if (btnBubbleTextCharsTL.time() < exitTime || btnBubbleCirclTL.time() < exitTime2) {
            btnBubbleTextCharsTL.play();
            btnBubbleCirclTL.play()
         } else {
            btnBubbleTextCharsTL.restart()
            btnBubbleCirclTL.restart()
         }
         //if (btnBubbleCirclTL.time() < exitTime2) {
         //   btnBubbleCirclTL.play()
         //} else {
         //   btnBubbleCirclTL.restart()
         //}
      })

      btn.addEventListener("mouseleave", () => {

         cursor.style.transform = "scale(0.25)";

         if (btnBubbleTextCharsTL.time() < exitTime || btnBubbleCirclTL.time() < exitTime2) {
            btnBubbleTextCharsTL.reverse();
            btnBubbleCirclTL.reverse()
         } else {
            btnBubbleTextCharsTL.restart();
            if (btnBubbleCirclLeaveTL.time() < exitTime3) {
               btnBubbleCirclLeaveTL.play()
            } else {
               btnBubbleCirclLeaveTL.restart()
            }
         }
         //if (btnBubbleCirclTL.time() < exitTime) {
         //   btnBubbleCirclTL.reverse()
         //} else {
         //   if (btnBubbleCirclLeaveTL.time() < exitTime3) {
         //      btnBubbleCirclLeaveTL.play()
         //   } else {
         //      btnBubbleCirclLeaveTL.restart()
         //   }
         //}

      })
   });


   const btnBubbleMagnetArea = document.querySelectorAll(".btn-bubble__magnet-area");

   btnBubbleMagnetArea.forEach((btn) => {
      // 1. Set the function and variables
      function parallaxItBtn(e, target, movement = 0.2) {
         var boundingRect = btn.getBoundingClientRect();
         var relX = e.pageX - boundingRect.left;
         var relY = e.pageY - boundingRect.top;
         var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

         gsap.to(target, {
            x: (relX - boundingRect.width / 2) * movement,
            y: (relY - boundingRect.height / 2 - scrollTop) * movement,
            ease: "power4",
            duration: 0.8
         });
      }

      // 2. Call the function
      function callParallaxBtn(e) {
         parallaxItBtn(e, btn);
      }
      btn.addEventListener('mousemove', function (e) {
         callParallaxBtn(e);
      });

      btn.addEventListener('mouseleave', function (e) {
         gsap.to(btn, {
            scale: 1,
            x: 0,
            y: 0,
            ease: "power1",
            duration: 1.4
         });
      });
   });
});